exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-auth-js": () => import("./../../../src/pages/account/auth.js" /* webpackChunkName: "component---src-pages-account-auth-js" */),
  "component---src-pages-account-books-js": () => import("./../../../src/pages/account/books.js" /* webpackChunkName: "component---src-pages-account-books-js" */),
  "component---src-pages-account-dashboard-js": () => import("./../../../src/pages/account/dashboard.js" /* webpackChunkName: "component---src-pages-account-dashboard-js" */),
  "component---src-pages-account-settings-js": () => import("./../../../src/pages/account/settings.js" /* webpackChunkName: "component---src-pages-account-settings-js" */),
  "component---src-pages-account-templates-js": () => import("./../../../src/pages/account/templates.js" /* webpackChunkName: "component---src-pages-account-templates-js" */),
  "component---src-pages-admin-dashboard-js": () => import("./../../../src/pages/admin/dashboard.js" /* webpackChunkName: "component---src-pages-admin-dashboard-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-customize-product-slug-book-id-js": () => import("./../../../src/pages/customize/{product.slug}/[bookId].js" /* webpackChunkName: "component---src-pages-customize-product-slug-book-id-js" */),
  "component---src-pages-customize-product-slug-index-js": () => import("./../../../src/pages/customize/{product.slug}/index.js" /* webpackChunkName: "component---src-pages-customize-product-slug-index-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-forgot-js": () => import("./../../../src/pages/forgot.js" /* webpackChunkName: "component---src-pages-forgot-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-invites-invite-id-js": () => import("./../../../src/pages/invites/[inviteId].js" /* webpackChunkName: "component---src-pages-invites-invite-id-js" */),
  "component---src-pages-orders-order-id-js": () => import("./../../../src/pages/orders/[orderId].js" /* webpackChunkName: "component---src-pages-orders-order-id-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-products-[name]-js": () => import("./../../../src/pages/products/[name].js" /* webpackChunkName: "component---src-pages-products-[name]-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-products-product-category-product-slug-cover-color-js": () => import("./../../../src/pages/products/{product.category}/{product.slug}/[coverColor].js" /* webpackChunkName: "component---src-pages-products-product-category-product-slug-cover-color-js" */),
  "component---src-pages-products-product-category-product-slug-index-js": () => import("./../../../src/pages/products/{product.category}/{product.slug}/index.js" /* webpackChunkName: "component---src-pages-products-product-category-product-slug-index-js" */),
  "component---src-pages-return-policy-js": () => import("./../../../src/pages/return-policy.js" /* webpackChunkName: "component---src-pages-return-policy-js" */),
  "component---src-pages-signin-js": () => import("./../../../src/pages/signin.js" /* webpackChunkName: "component---src-pages-signin-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-subscription-js": () => import("./../../../src/pages/subscription.js" /* webpackChunkName: "component---src-pages-subscription-js" */)
}

